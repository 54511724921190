import "./form.css";

export default function Form() {
  return (
    <form
      method="POST"
      name="contactform"
      className="contactForm"
      // action="/pages/success"
    >
      <input type="hidden" name="form-name" value="contactForm" />

      <input type="text" name="name" placeholder="Naam" />

      <input type="email" name="email" placeholder="Email" />

      <textarea
        name="message"
        placeholder="Wij hebben er super veel zin in, jullie ook?!🥳"
      ></textarea>

      <button type="submit">Submit</button>
    </form>
  );
}
