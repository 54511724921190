import { Box, Grid } from "@mui/material";
import React from "react";
import buildingImage from "../images/building.png";
import dresscodeImage from "../images/dresscode.png";
import giftImage from "../images/gift.png";
import ringsImage from "../images/rings.png";

interface SquareProps {
  image: string;
  title: string;
  text: string;
}

const squareStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "200px",
  margin: "0px",
  borderRadius: "5px",
};

const textStyle: React.CSSProperties = {
  fontSize: "14px",
  textAlign: "center",
};

const Square: React.FC<SquareProps> = ({ image, title, text }) => {
  // Split the text by newline character '\n'
  const textLines = text.split("\\n");
  console.log(textLines);

  return (
    <div style={squareStyle}>
      <img
        src={image}
        alt={title}
        style={{ width: "90px", height: "90px", paddingBottom: "10px" }}
      />
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Parisienne",
        }}
      >
        {title}
      </div>
      {textLines.map((line, index) => (
        <div key={index} style={textStyle}>
          {line}
        </div>
      ))}
    </div>
  );
};

export default function Information() {
  // return (
  //   <Box sx={{ flexGrow: 1 }}>
  //     <Grid
  //       container
  //       spacing={{ xs: 2, md: 3 }}
  //       columns={{ xs: 4, sm: 8, md: 12 }}
  //     >
  //       {Array.from(Array(4)).map((_, index) => (
  //         <Grid item xs={2} sm={4} md={4} key={index}>
  //           <Square
  //             image={buildingImage}
  //             title="Locatie"
  //             text="Landgoed de Salentein\nPutterstraatweg 5-9, 3862RA Nijkerk"
  //           />
  //         </Grid>
  //       ))}
  //     </Grid>
  //   </Box>
  // );
  return (
    <Box display="flex" justifyContent="center" width="80%" margin="auto">
      <Grid container spacing={3}>
        <Grid item xs={3} style={{ height: "100px" }}>
          <Square
            image={buildingImage}
            title="Locatie"
            text="Landgoed de Salentein\nPutterstraatweg 5-9, 3862RA Nijkerk"
          />
        </Grid>
        <Grid item xs={3}>
          <Square image={dresscodeImage} title="Dresscode" text="Summer Chic" />
        </Grid>
        <Grid item xs={3}>
          <Square image={giftImage} title="Cadeautip" text="blabla" />
        </Grid>
        <Grid item xs={3}>
          <Square image={ringsImage} title="Adres bruidspaar" text="blabla" />
        </Grid>
      </Grid>
    </Box>
  );
}
