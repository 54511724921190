import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";

const theme: Theme = responsiveFontSizes(
  createTheme({
    spacing: 4,
    typography: {
      fontFamily: ["Hind", "Open Sans"].join(","),
      h1: {
        fontFamily: "Parisienne",
      },
      h2: {
        fontFamily: "Open Sans",
        fontSize: "32px",
      },
      h5: {
        fontFamily: "Open Sans",
      },
    },
    palette: {
      //   background: {
      //     default: "#009900", // green
      //   },
      primary: {
        main: "#391f17", // indigo
      },
      secondary: {
        main: "#965d4a", // pink
      },
      text: {
        primary: "#000000", // black
        secondary: "#FFFFFF", // white
      },
    },
  })
);

export default theme;
