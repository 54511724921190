import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { ThemeProvider } from "@mui/material/styles";
import Hero from "./components/1_Hero";
import Invitation from "./components/2_Invitation";
import CountdownTimer from "./components/3_CountdownTimer";
import Information from "./components/4_Information";
import Form from "./components/5_Form";
import theme from "./theme";

const targetDate = "2024-08-16T00:00:00";

export default function LandingPage() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        <Invitation />
        <Divider />
        <CountdownTimer targetDate={targetDate} />
        <Divider />
        <Information />
        <Divider />
        <Form />
      </Box>
    </ThemeProvider>
  );
}
