import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import backgroundImg from "../images/hero-background.png";

export default function Hero() {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        sx={{
          display: "absolute",
          flexDirection: "column",
          alignItems: "center",
          pt: 100,
          // pb: { xs: 8, sm: 34 },
          pb: 100,
        }}
      >
        <Stack>
          <Typography variant="h1" textAlign="center" color="primary">
            Sander & Amber
          </Typography>
          <Typography variant="h3" textAlign="center" color="secondary">
            Wij gaan trouwen!
          </Typography>
          <Typography variant="h5" textAlign="center" color="primary">
            16 augustus 2024
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
