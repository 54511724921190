import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function Invitation() {
  return (
    <Box id="invitation" sx={{ py: 4, pt: 10 }}>
      <Typography component="p" variant="h5" align="center" color="primary">
        Uitnodiging voor onze bruiloft
      </Typography>
      <Typography
        variant="subtitle2"
        textAlign="center"
        color="secondary"
        sx={{ pt: 2, maxWidth: "40%", margin: "0 auto" }}
      >
        Het gaat dan toch eindelijk gebeuren, wij gaan trouwen!
        <br /> En deze dag vieren we natuurlijk niet zonder jou! <br />
        Daarom willen we je van harte uitnodigen voor onze bruiloft! <br />
        <br />
        Kom je ook? Laat het ons weten door het RSVP-formulier in te vullen.
        <br /> Ook als je er niet bij kunt zijn, vernemen we dat graag. Hopelijk
        tot snel!
      </Typography>
      <Box sx={{ textAlign: "center", pt: 10, pb: 10 }}>
        <Button variant="contained" color="secondary" size="large">
          RSVP
        </Button>
      </Box>
    </Box>
  );
}
