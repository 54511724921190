import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import "./App.css";
import LandingPage from "./LandingPage";
import theme from "./theme";

// https://templates.invited-you.com/designs-bruiloft/bloemen/
// https://ella.gaantrouwen.nl/

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <LandingPage></LandingPage>
    </ThemeProvider>
  );
};

export default App;
