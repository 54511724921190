import React, { useEffect, useState } from "react";

interface CountdownTimerProps {
  targetDate: string; // Format 'YYYY-MM-DDTHH:mm:ss'
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate).getTime() - new Date().getTime();
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div
      style={{
        display: "flex",
        width: "30%",
        margin: "0 auto",
      }}
    >
      <div style={{ flex: 1, textAlign: "center", width: "25%" }}>
        <h2 style={{ fontSize: "32px" }}>{timeLeft.days}</h2>
        <p style={{ fontSize: "16px", fontFamily: "Parisienne" }}>dagen</p>
      </div>
      <div style={{ flex: 1, textAlign: "center", width: "25%" }}>
        <h2 style={{ fontSize: "32px" }}>{timeLeft.hours}</h2>
        <p style={{ fontSize: "16px", fontFamily: "Parisienne" }}>uren</p>
      </div>
      <div style={{ flex: 1, textAlign: "center", width: "25%" }}>
        <h2 style={{ fontSize: "32px" }}>{timeLeft.minutes}</h2>
        <p style={{ fontSize: "16px", fontFamily: "Parisienne" }}>minuten</p>
      </div>
      <div style={{ flex: 1, textAlign: "center", width: "25%" }}>
        <h2 style={{ fontSize: "32px" }}>{timeLeft.seconds}</h2>
        <p style={{ fontSize: "16px", fontFamily: "Parisienne" }}>seconden</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
